import Info from '@components/Info'
import { List, ListItem } from '@components/List'
import { NavItem, NavLink } from '@components/Nav'
import { Navbar, NavbarBrand, NavbarCollapse, NavbarCollapseHeader, NavbarNav, NavbarSubnav, NavbarToggler } from '@components/Navbar'
import atomCollapsed from '@components/Navbar/atoms/Collapsed'
import Portal from '@components/Portal'
import Button from '@components/UI/Button'
import ButtonGroup from '@components/UI/ButtonGroup'
import Divider from '@components/UI/Divider'
import Icon from '@components/UI/Icon'
import Link from '@components/UI/Link';
import Logo from '@components/UI/Logo'
import Heading from '@components/UI/Typography/Heading'
import Paragraph from '@components/UI/Typography/Paragraph'
import useEventHandler from '@hooks/useEventHandler';
import useUtmTags from '@hooks/useUtmTags';

import RenderIf from '@utils/renderIf'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'

import Navs from './data'

const Navigation = ({ theme, background }) => {
    const { pathname, isReady } = useRouter()
    const [collapsed, setCollapsed] = useRecoilState(atomCollapsed)

    const [clickedIndexes, setClickedIndexes] = useState([])

    const breakWidth = 1280

    const handleNavClick = (index) => {
        if (window.innerWidth > breakWidth) return
        const updatedClickedIndexes = [...clickedIndexes]

        if (updatedClickedIndexes.includes(index)) {
            updatedClickedIndexes.splice(updatedClickedIndexes.indexOf(index), 1)
        } else {
            updatedClickedIndexes.push(index)
        }

        setClickedIndexes(updatedClickedIndexes)
    }

    const handleTogglerClick = () => setCollapsed(!collapsed)

    const onResize = () => {
        // reset nav
        if (window.innerWidth > breakWidth) {
            setCollapsed(false)
            setClickedIndexes([])
        }
    };

    useEventHandler('resize', onResize);

    useEffect(() => setCollapsed(false), [])

    const OffCanvas = (
        <NavbarCollapse>
            <NavbarCollapseHeader>
                <NavbarBrand
                    href='/'
                >
                    <Logo
                        type='dark'
                    />
                </NavbarBrand>

                <NavbarToggler
                    onClick={ handleTogglerClick }
                >
                    <Icon
                        name={ 'burger-' + (!collapsed ? 'open' : 'close') }
                        color='neutral-900'
                        size={ 32 }
                        mods={ { block: true } }
                    />
                </NavbarToggler>
            </NavbarCollapseHeader>

            <NavbarNav>
                { Navs.map(nav => (
                    <NavItem
                        key={ nav.id }
                        onClick={ () => {
                            handleNavClick(nav.id)
                        } }
                    >
                        <NavLink
                            className='whitespace-nowrap'
                            href={ nav.link }
                        >
                            { nav.title }
                            { nav.subnav && (
                                <Icon
                                    className={ clickedIndexes.includes(nav.id) ? 'rotate-180' : '' }
                                    name={ 'chevron-down' }
                                    size={ 12 }
                                    color={ '#' }
                                />
                            ) }
                        </NavLink>

                        <RenderIf
                            isTrue={ nav.subnav }
                        >
                            <NavbarSubnav
                                mods={ { show: clickedIndexes.includes(nav.id) } }>
                                <Info
                                    gap={ 4 }
                                >
                                    <div className={ 'heading h2' }>
                                        { nav.subnav_title_link ? (
                                            <Link
                                                href={ nav.subnav_title_link }
                                                linkProps={ { title: nav.subnav_title_alt } }
                                            >
                                                { nav.title }
                                            </Link>
                                        ) : (
                                            nav.title
                                        ) }
                                    </div>

                                    { <Paragraph>
                                        { nav.desc }
                                    </Paragraph> }
                                </Info>

                                <List
                                    mods={ { subnav: true } }
                                >
                                    { nav.subnav?.map(subnav => {
                                        const navLink = <>
                                            <Icon
                                                name={ subnav.icon }
                                                color={ ['violet-500', 'neutral-700'] }
                                                size={ 32 }
                                            />

                                            <Info
                                                gap={ 2 }
                                            >
                                                <Heading
                                                    tag='span'
                                                    className='h4'
                                                >
                                                    { subnav.title }
                                                </Heading>

                                                { <Paragraph
                                                    tag='p'
                                                >
                                                    { subnav.desc }
                                                </Paragraph> }
                                            </Info>
                                        </>

                                        return (
                                            <ListItem
                                                key={ subnav.id }
                                            >
                                                <NavLink
                                                    href={ subnav.link }
                                                >
                                                    { navLink }
                                                </NavLink>
                                            </ListItem>
                                        )
                                    }) }
                                </List>
                            </NavbarSubnav>
                        </RenderIf>
                    </NavItem>
                )) }
            </NavbarNav>

            <ButtonGroup
                direction='col'
            >
                <Button
                    tag='a'
                    type='link'
                    href={ `${ process.env.NEXT_PUBLIC_APP_TRAQQ_URL }/signup` }
                    variant='primary'
                    mods={ { signup: true } }
                >
                    Organization Sign Up
                </Button>

                <Button
                    tag='a'
                    type='link'
                    href={ `${ process.env.NEXT_PUBLIC_APP_TRAQQ_URL }/login` }
                    color='violet'
                    variant='outline_primary'
                    mods={ { signin: true } }
                >
                    Log In
                </Button>

                <Button
                    tag='a'
                    type='link'
                    href={useUtmTags('/demo', {utm_medium: 'CTA_header'})}
                    color='violet'
                    variant='outline_primary'
                    mods={ { demo: true } }
                    className="768:hidden"
                >
                    Demo
                </Button>
            </ButtonGroup>
        </NavbarCollapse>
    )

    useEffect(() => {
        if (isReady) {
            let body = document.getElementsByTagName('body')[0]

            collapsed ? body.classList.add('no-scroll') : body.classList.remove('no-scroll')
        }
    }, [collapsed])

    return (
        <>
            <Navbar
                mods={ {
                    background,
                    theme,
                    sticky: true
                } }
            >
                <div className='flex items-center justify-between py-4 gap-x-16p 1280:py-7 container'>
                    <div className='flex justify-between w-full'>
                        <NavbarBrand
                            href='/'
                            title='Go to the main page'
                            className='!mr-0'
                        >
                            <Logo
                                type={ theme === 'dark' ? 'light' : 'dark' }
                            />
                        </NavbarBrand>

                        <RenderIf
                            isTrue={ !collapsed }
                        >
                            { OffCanvas }
                        </RenderIf>
                    </div>

                    <ButtonGroup
                        mix={ { 'navbar-button-group': true } }
                    >
                        <Button
                            tag='a'
                            type='link'
                            href={useUtmTags('/demo', {utm_medium: 'CTA_header'})}
                            variant='tertiary'
                            mods={ {
                                demo: true,
                                white: pathname.includes('traqq-discount')
                            } }
                            className='!py-10p !px-20p'
                        >
                            Demo
                        </Button>

                        <Divider
                            variant='vertical'
                            style='dotted'
                        />

                        <Button
                            id='button-login'
                            tag='a'
                            type='link'
                            href={ `${ process.env.NEXT_PUBLIC_APP_TRAQQ_URL }/login` }
                            variant='tertiary'
                            mods={ {
                                signin: true,
                                white: pathname.includes('traqq-discount')
                            } }
                            className='!hidden 480:!inline whitespace-nowrap !py-10p !px-20p'
                        >
                            Log In
                        </Button>

                        <Button
                            tag='a'
                            type='link'
                            href={ useUtmTags(`${ process.env.NEXT_PUBLIC_APP_TRAQQ_URL }/signup`, {utm_medium: 'CTA_header'}) }
                            variant={ background !== 'violet' ? 'primary' : 'secondary' }
                            mods={ { signup: true } }
                            className='!text-14p whitespace-nowrap max-480:!p-2.5 max-480:!leading-3 !px-23p !py-10p'
                        >
                            <Icon
                                name={ 'enter' }
                                size={ 20 }
                                color={ '#F9F9FD' }
                                className='480:!hidden'
                            />
                            <span className="max-480:hidden">Organization Sign Up</span>
                        </Button>

                        <NavbarToggler
                            mods={ { 'sm-dark': pathname.includes('traqq-discount') } }
                            onClick={ handleTogglerClick }
                            className='ml-4 480:ml-6'
                        >
                            <Icon
                                name={ 'burger-' + (!collapsed ? 'open' : 'close') }
                                color={ theme === 'dark' ? '#ffffff' : 'neutral-900' }
                                mods={ { block: true } }
                            />
                        </NavbarToggler>
                    </ButtonGroup>
                </div>
            </Navbar>

            <RenderIf
                isTrue={ collapsed }
            >
                <Portal>
                    { OffCanvas }
                </Portal>
            </RenderIf>
        </>
    )
}

Navigation.displayName = 'Navigation'

Navigation.propTypes = {
    background: PropTypes.string,
    theme: PropTypes.string
}

export default Navigation
